import React from "react"
import styled from "styled-components"
import { color } from "../components/colors"
import { footer } from "../components/general-config"
import Icon from "../atomic/atoms/icon"
import LogoSVG from "../images/footer-logo.inline.svg"
import Container from "../atomic/partials/container"

const Wrapper = styled.footer`
  display: flex;
  align-items: center;
  background-color: ${footer.backgroundColor};
  padding: 21px 0;
`

const Copyright = styled.span`
  ${footer.typography ? footer.typography : null}
  color: ${footer.color};
  text-align: center;
`

const Author = styled.a`
  ${footer.typography ? footer.typography : null}
  color: ${footer.color};
  text-decoration: none;
  transition: color 0.3s ease;
`

const Footer = () => {
  return (
    <Wrapper>
      <Container flex column directionMD="row" justify="space-between" align="center">
        <Icon icon={<LogoSVG />} height="16px" width="157px" mt="6px"/>
        <Copyright align={"center"} p={"0 0 15px"}>
        © {new Date().getFullYear()} Wszystkie prawa zastrzeżone.
        {' '}
        <span style={{whiteSpace: "nowrap"}}>Realizacja:
        {' '}
          <Author
            href="https://www.facebook.com/webscris"
            target="_blank"
            rel="noreferrer"
          >
            Cris
          </Author>
          </span>
        </Copyright>
      </Container>
    </Wrapper>
  )
}

export default Footer
