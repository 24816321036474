export const color = {
  blue00: "#263273",
  blue20: "#334399",
  blue40: "#3F53C0",
  blue60: "#8698F9",
  blue80: "#B6C1FB",
  blue100: "#E5E9FF",
  green00: "#26734C",
  green20: "#339966",
  green40: "#3FC07F",
  green60: "#8CD9B2",
  green80: "#B2E6CC",
  green100: "#ECF9F2",
  black: "#080808",
  neutral20: "#303136",
  neutral40: "#6F7076",
  neutral60: "#BEBEC1",
  neutral80: "#F0F1F5",
  neutral90: "#F9FBFA",
  white: "#ffffff",
}

export const gradient = {
  babyblue2: "linear-gradient(0deg, rgba(255,255,255,0.00) 4%, #E0F4FD 99%)",
}
