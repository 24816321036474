import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import { color } from "./colors"
import Container from "../atomic/partials/container"
import { menu } from "./general-config"

const CustomHeader = styled.header`
  display: flex;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 999;
  padding: 32px 0;
  background-color: ${menu.backgroundColor ? menu.backgroundColor[0] : ""};
  border-bottom: ${menu.borderBottom ? menu.borderBottom[0] : ""};
  transition: padding 0.3s ease;

  @media (min-width: 992px) {
    align-items: center;
  }

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &.alt {
    background-color: ${menu.backgroundColor ? menu.backgroundColor[1] : ""};
    border-bottom: ${menu.borderBottom ? menu.borderBottom[1] : ""};
    padding: 16px 0;

    .hamburger span {
      background-color: ${menu.hamburgerColor ? menu.hamburgerColor[0] : ""};
    }

    nav {
      //top: 55px;
      background-color: ${menu.navigationColor ? menu.navigationColor[0] : ""};
      border-bottom: ${menu.borderBottom ? menu.borderBottom[1] : ""};
      transform: translateY(-32px);

      @media (min-width: 992px) {
        border-bottom-width: 0;
        transform: translateY(0);
      }
    }

    .logo {
      color: #fff;
    }

    ul {
      display: flex;

      li {
        
        // &:not(:last-child) {
        //   a {
        //     color: ${menu.color[1]};
        //   }
        // }

        // &:last-child {
        //   a {
        //     background-color: ${menu.backgroundColor[0]};
        //     border: 2px solid ${menu.backgroundColor[0]};
        //     color: ${menu.color[1]};

        //     &:hover {
        //       background-color: ${menu.fontBackgroundColor[1]};
        //       border: 2px solid ${menu.fontBackgroundColor[1]};
        //       color: ${menu.colorHover[0]};
        //     }
        //   }
        // }
      }
    }
  }

  svg {
    height: 14px;
    margin-right: 8px;

    path {
      fill: ${color.white};
    }
  }
`

const LogoWrapper = styled(Link)`
  width: 100%;
  //position: relative;
  text-decoration: none;
  text-transform: uppercase;
  font-family: Poppins, sans-serif;
  color: ${menu.navigationColor ? menu.navigationColor[1] : ""};
  font-size: 18px;
  line-height: 1;
  font-weight: 700;

  @media (min-width: 576px) {
    max-width: 140px;
  }

  @media (min-width: 992px) {
    max-width: 207px;
  }

  span {
    font-size: 32px;
  }
`

const Navigation = styled.nav`
  height: 288px;
  position: absolute;
  left: 0;
  right: 0;
  top: 110px;
  background-color: ${menu.navigationColor ? menu.navigationColor[0] : ""};
  transition: height 0.3s ease, transform 0.3s ease;
  overflow: hidden;

  @media (min-width: 992px) {
    position: static;
    background-color: transparent;
    background-image: none;
    height: auto;
    overflow: visible;
  }

  &.hidden {
    height: 0px;

    @media (min-width: 992px) {
      height: auto;
    }
  }
`;

const NavList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 900px;
  list-style: none;
  padding: 32px 15px;
  margin: 0 auto;

  @media (min-width: 576px) {
    max-width: 576px;
  }

  @media (min-width: 768px) {
    max-width: 768px;
  }

  @media (min-width: 992px) {
    max-width: 900px;
  }

  @media (min-width: 992px) {
    max-width: 1220px;
    flex-direction: row;
    padding: 0;
  }

  li {
    display: flex;
    align-items: center;
    width: 100%;
    // background-color: ${menu.fontBackgroundColor ? menu.fontBackgroundColor[0] : ""};
    // border-radius: 56px;
    //padding: 8px 24px;
    margin-bottom: 0;
    //transition: background-color 0.3s ease;

    // &:hover {
    //   background-color: ${menu.fontBackgroundColor ? menu.fontBackgroundColor[1] : ""};
    // }

    @media (min-width: 992px) {
      width: auto;
    }
  }

  li {
    margin-bottom: 16px;

    a {
      ${menu.typography ? menu.typography : null}
      color: ${menu.color[0]};
      background-color: ${menu.fontBackgroundColor ? menu.fontBackgroundColor[0] : ""};
      border-radius: 56px;
      text-transform: ${menu.textTransform ? menu.textTransform : ""};
      text-decoration: none;
      padding: 8px 24px;
      transition: color 0.3s ease, background-color 0.3s ease;
      position: relative;

      // &::before {
      //   content: "";
      //   width: 0%;
      //   border-bottom: 1px solid #fff;
      //   transition: width 0.3s ease;
      //   position: absolute;
      //   bottom: -8px;
      // }

      &:hover {
        color: ${menu.colorHover[0]};
        background-color: ${menu.fontBackgroundColor ? menu.fontBackgroundColor[1] : ""};
        // &::before {
          
        //   width: 100%;
        // }
      }
    }

    @media (min-width: 992px) {
      &:not(:last-child) {
        margin-right: 32px;
      }
      margin-bottom: 0;
    }
  }
`

const Hamburger = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 45px;
  width: 45px;
  padding: 5px;
  position: relative;
  background: transparent;
  border: 0;
  cursor: pointer;
  z-index: 1;

  @media (min-width: 992px) {
    display: none;
  }

  &:focus {
    outline-color: #fff;
  }

  span {
    display: block;
    height: 6px;
    width: 35px;
    background-color: ${menu.hamburgerColor ? menu.hamburgerColor[0] : ""};
    border-radius: 2px;
    position: relative;
    transition: background-color 0.3s ease;
  }

  // &:hover {
  //   span {
  //     background-color: ${color.blue40};
  //   }
  // }

  &.active {
    justify-content: center;
    padding: 2px;

    span {
      position: absolute;
      transition: transform 0.3s ease;

      &:nth-child(1) {
        top: calc(50% - 3px);
        transform: rotate(45deg);
        transform-origin: center;
      }

      &:nth-child(2) {
        display: none;
      }

      &:nth-child(3) {
        bottom: calc(50% - 3px);
        transform: rotate(-45deg);
        transform-origin: center;
      }
    }
  }
`

const Header = ({ siteTitle }) => {
  const [hamburgerIsActive, setHamburgerIsActive] = useState(false)

  useEffect(() => {
    const hero = document.querySelector(".top-overlay")
    const header = document.querySelector(".header")

    if (hero) {
      const heroSize = hero.offsetHeight / 10

      if (document.documentElement.scrollTop > heroSize) {
        header.classList.add("alt")
      } else {
        header.classList.remove("alt")
      }

      window.onscroll = () => {
        if (document.documentElement.scrollTop > heroSize) {
          header.classList.add("alt")
        } else {
          header.classList.remove("alt")
        }
      }
    } else {
      header.classList.add("alt")
    }
  })

  const handleClick = e => {
    e.target.closest("button").classList.toggle("active")
    setHamburgerIsActive(!hamburgerIsActive)
  }

  const handleMenuItemClick = e => {
    e.target.closest("ul").parentElement.classList.remove("active")
    e.target.closest("ul").parentElement.previousSibling.classList.remove("active")
    setHamburgerIsActive(!hamburgerIsActive)
  }

  return (
    <CustomHeader className="header">
      <Container position="static">
        <LogoWrapper className="logo" to="#home">
          <StaticImage
            src="../images/logo.png"
            quality={85}
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="A Gatsby astronaut"
            placeholder="tracedSVG"
          />
        </LogoWrapper>
        <Hamburger
        className="hamburger" 
        onClick={e => handleClick(e)}
        >
          <span />
          <span />
          <span />
        </Hamburger>
        <Navigation className={hamburgerIsActive ? "active" : "hidden"}>
          <NavList>
            <li>
              <Link to="/#o-nas" onClick={e => handleMenuItemClick(e)}>O nas</Link>
            </li>
            <li>
              <Link to="/#nasza-oferta" onClick={e => handleMenuItemClick(e)}>Zakres usług</Link>
            </li>
            <li>
              <Link to="/#nasza-kadra" onClick={e => handleMenuItemClick(e)}>Personel</Link>
            </li>
            <li>
              <Link to="/#kontakt" onClick={e => handleMenuItemClick(e)}>Kontakt</Link>
            </li>
          </NavList>
        </Navigation>
      </Container>
    </CustomHeader>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
