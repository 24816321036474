export const typography = {
    h900: "font-size: 72px; line-height: 88px; font-weight: 500; letter-spacing: 0.02em;",
    h800: "font-size: 46px; line-height: 56px; font-weight: 500; letter-spacing: 0.02em;",
    h700: "font-size: 36px; line-height: 54px; font-weight: 500; letter-spacing: 0.02em;",
    h600: "font-size: 32px; line-height: 48px; font-weight: 500; letter-spacing: 0.02em;",
    h500: "font-size: 24px; line-height: 36px; font-weight: 500; letter-spacing: 0.02em;",
    h400: "font-size: 22px; line-height: 33px; font-weight: 500; letter-spacing: 0.02em;",
    heading: "font-size: 16px; line-height: 24px; font-weight: 500; letter-spacing: 0.02em;",
    bodyL: "font-size: 18px; line-height: 28px; font-weight: 400; letter-spacing: 0.02em;",
    bodyM: "font-size: 16px; line-height: 22px; font-weight: 400; letter-spacing: 0.02em;",
    bodyS: "font-size: 14px; line-height: 22px; font-weight: 400; letter-spacing: 0.02em;",
    menuPosition: "font-size: 16px; line-height: 24px; font-weight: 500; letter-spacing: 0.02em;",
    button: "font-size: 16px; line-height: 24px; font-weight: 600; letter-spacing: 0.02em;",
    buttonS: "font-size: 14px; line-height: 22px; font-weight: 500; letter-spacing: 0.02em;",
    buttonXS: "font-size: 12px; line-height: 20px; font-weight: 500; letter-spacing: 0.02em;",
    point: "font-size: 16px; line-height: 24px; font-weight: 400; letter-spacing: 0.02em;",
  }
  