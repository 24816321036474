import { typography } from "./typography"
import { color } from "./colors"

export const menu =  {
    typography: typography.menuPosition,
    backgroundColor: [color.white, color.white],
    color: [color.neutral20, color.neutral20],
    colorHover: [color.blue40, color.blue40],
    fontBackgroundColor: [color.white, color.blue100],
    borderBottom: [ `1px solid rgba(255, 255, 255, 0.2)`, `1px solid ${color.neutral80}`],
    hamburgerColor: [color.blue40, color.blue40],
    navigationColor: [color.white, color.blue40],
    textTransform: "",
}

export const header = {
    size: 2,
    typography: [typography.h900, typography.h700, typography.h500],
    color: color.neutral20,
    secondaryColor: color.green20,
    textTransform: "",
    padding: "4px 0 16px",
    margin: "",
    position: "relative",
}

export const text = {
    typography: typography.bodyM,
    linkTypography: typography.button,
    textTransform: "",
}

export const link = {
    typography: typography.menuPosition,
    textTransform: "",
    textDecoration: "none",
    transition: "color 0.3s ease"
}

export const button = {
    typography: typography.buttonXS,
    typographyXXS: typography.buttonS,
    typographyXS: typography.button,
    width: "",
    height: "",
    backgroundColor: [color.blue40, color.white],
    color: color.white,
    textTransform: "uppercase",
    position: "relative",
}

export const footer = {
    typography: typography.bodyS,
    backgroundColor: color.green20,
    color: color.white,
}